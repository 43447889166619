import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QCard } from '../../components/Q-Components/QCard';
import { toastrHelper } from '../../logic/toastrHelper';
import { QTypography } from '../../components/Q-Components/QTypography';
import { QCardHeader } from '../../components/Q-Components/QCardHeader';
import { setPageTitle } from '../../store/slices/emmAppSlice';
import { QDivider } from '../../components/Q-Components/QDivider';
import { QTableRow } from '../../components/Q-Components/QTableRow';
import { QTable } from '../../components/Q-Components/QTable';
import { QTableHead } from '../../components/Q-Components/QTableHead';
import { QTableBody } from '../../components/Q-Components/QTableBody';
import { QTableCell } from '../../components/Q-Components/QTableCell';
import { QContainer } from '../../components/Q-Components/QContainer';
import { PermissionsChartCard } from '../../components/Sarif/PermissionsChartCard';
import { IorsChartCard } from '../../components/Sarif/IorsChartCard';
import IssueCard from '../../components/Reports/IssueCard';
import { AppSummary } from '../../components/Reports/AppSummary';
import { aggregateResultsForSingleRule } from '../../logic/dopplerUtil';
import {
  SharedFilterSelects,
  filterByNestedProperty,
} from '../../components/SharedFilterSelects';

export function NewReport() {
  const { newQuokkaReport, mastV2 } = useFlags();

  const dispatch = useDispatch();

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [aggregateData, setAggregateData] = useState({});
  const [ruleSummaries, setRuleSummaries] = useState([]);
  const [filteredRuleSummaries, setFilteredRuleSummaries] = useState([]);
  const { farmAppUuid, analyzedAppId } = useParams();
  const [app, setApp] = useState();

  const sarifBaseURL = '/analysis-results';

  // TODO: Remove this check once data is loaded into database
  // https://quokka-io.atlassian.net/browse/MAST-3841
  const checkDataLoaded = async () => {
    if (isDataLoaded) return;
    try {
      await axios.get(`${sarifBaseURL}/is-data-loaded?uuid=${farmAppUuid}`);

      setIsDataLoaded(true);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error checking if sarif data is loaded',
        'Error',
        mastV2,
      );
    }
  };

  const getApp = async () => {
    try {
      if (analyzedAppId) {
        const { data } = await axios.get(`/emm/app/${analyzedAppId}`);
        setApp(data);
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading analyzed app data',
        'Error',
        mastV2,
      );
    }
  };

  const fetchAggregateData = async () => {
    try {
      const { data } = await axios.get(
        `${sarifBaseURL}/aggregate-data?uuid=${farmAppUuid}`,
      );
      setAggregateData(data);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading summary data',
        'Error',
        mastV2,
      );
    }
  };

  const fetchRuleSummaries = async () => {
    try {
      const { data } = await axios.get(
        `${sarifBaseURL}/rule-summaries?uuid=${farmAppUuid}`,
      );
      data.sort((a, b) => b.result_level_numeric - a.result_level_numeric);

      setRuleSummaries(
        data.map(ruleSummary => ({
          ...ruleSummary,
          details: aggregateResultsForSingleRule(
            ruleSummary.rule,
            {},
            ruleSummary.result_level_numeric,
          ),
        })),
      );
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading rule summary data',
        'Error',
        mastV2,
      );
    }
  };

  useEffect(() => {
    checkDataLoaded();
    if (!isDataLoaded) return;
    fetchAggregateData();
    getApp();
    fetchRuleSummaries();
    dispatch(setPageTitle('New Report'));
  }, [isDataLoaded]);

  if (!newQuokkaReport) {
    return (
      <QContainer>
        <QTypography variant="h6">New Quokka Report is disabled</QTypography>
      </QContainer>
    );
  }

  const filtersConfig = [
    {
      key: 'riskLevel',
      label: 'Risk Level',
      filteredKey: 'details.risk',
      originalData: ruleSummaries,
    },
    {
      key: 'category',
      label: 'Category',
      filteredKey: 'details.category',
      originalData: ruleSummaries,
    },
    {
      key: 'compliance',
      label: 'Compliance',
      filteredKey: 'rule.properties.compliance',
      customHandleFilterChange: (filteredKey, data, option) =>
        filterByNestedProperty({
          data,
          filteredKey,
          optionValues: option.value,
          predicate: item => item.fail || item.kind === 'fail',
        }),
      customOptions: [
        { label: 'OWASP', value: ['owasp-2016', 'owasp-2024'] },
        { label: 'NIAP', value: 'niap' },
        { label: 'GDPR', value: 'gdpr' },
      ],
      originalData: ruleSummaries,
    },
  ];

  const handleFilteredData = filteredData => {
    setFilteredRuleSummaries(filteredData);
  };

  return (
    <QContainer
      fluid
      sx={{
        maxWidth: '100% !important',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
      }}
    >
      {app && <AppSummary app={app} />}

      <Grid container rowSpacing={2} columnSpacing={2}>
        {aggregateData?.permissionSummary && (
          <Grid item xs={5}>
            <PermissionsChartCard
              permissionsSummary={aggregateData.permissionSummary}
            />
          </Grid>
        )}
        {aggregateData?.levelSummary && (
          <Grid item xs={5}>
            <IorsChartCard
              resultSummary={aggregateData.levelSummary}
              title="Rules"
            />
          </Grid>
        )}
      </Grid>

      <>
        <QCard
          style={{
            padding: '0',
            marginBottom: '18px',
            boxShadow: 'none',
            borderRadius: '0',
          }}
        >
          <QCardHeader disableTypography title="Rules" />
          <QDivider />

          <SharedFilterSelects
            filtersConfig={filtersConfig}
            originalData={ruleSummaries}
            onFilteredData={handleFilteredData}
          />
        </QCard>
        <QTable data-testid="rules-table">
          <QTableHead>
            <QTableRow hoverDisabled>
              <QTableCell>Rules</QTableCell>
            </QTableRow>
          </QTableHead>
          <QTableBody>
            {filteredRuleSummaries?.map(ruleSummary => (
              <IssueCard
                ruleSummary={ruleSummary}
                uuid={farmAppUuid}
                key={ruleSummary.guid}
              />
            ))}
          </QTableBody>
        </QTable>
      </>
    </QContainer>
  );
}
