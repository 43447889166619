import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { FilterSelect } from './FilterSelect';

// Helper function to filter an array of objects
// based on a nested property path and provided values.
export const filterByNestedProperty = ({
  data,
  filteredKey,
  optionValues,
  predicate,
}) =>
  data.filter(item => {
    const value = _.get(item, filteredKey);
    if (!value) return false;

    if (Array.isArray(optionValues)) {
      return optionValues.some(option =>
        value[option]?.some(entry => predicate(entry)),
      );
    }
    return value[optionValues]?.some(entry => predicate(entry));
  });

export const SharedFilterSelects = ({
  filtersConfig,
  originalData,
  onFilteredData,
}) => {
  const initialFilters = filtersConfig.reduce((acc, filter) => {
    acc[filter.key] = { value: 'none' };
    return acc;
  }, {});

  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  // Handle individual filter changes
  const handleFilterChange = (key, value) => {
    setSelectedFilters(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  // Apply all selected filters to originalData
  const applyFilters = () => {
    let filteredData = [...originalData];

    filtersConfig.forEach(filter => {
      const { key, filteredKey, customHandleFilterChange } = filter;
      const selected = selectedFilters[key];

      if (selected.value === 'all' || selected.value === 'none') return; // Skip 'All' selections

      if (customHandleFilterChange) {
        filteredData = customHandleFilterChange(
          filteredKey,
          filteredData,
          selected,
        );
      } else {
        filteredData = filteredData.filter(
          item => _.get(item, filteredKey) === selected.value,
        );
      }
    });

    onFilteredData(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedFilters, originalData]);

  return (
    <div
      className="filters-container"
      style={{ display: 'flex', gap: '8px', padding: '16px' }}
    >
      {filtersConfig.map(filter => (
        <FilterSelect
          key={filter.key}
          selectedValue={selectedFilters[filter.key]}
          onChange={value => handleFilterChange(filter.key, value)}
          label={filter.label}
          filteredKey={filter.filteredKey}
          originalData={originalData}
          customOptions={filter.customOptions}
        />
      ))}
    </div>
  );
};
