import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { IssueAccordion } from '../IssueAccordion';

class QReportFindingCard extends Component {
  render() {
    const { issue } = this.props;

    return (
      <IssueAccordion issue={issue} issueWithEvidence={issue} isSarifReport />
    );
  }
}
export default withRouter(QReportFindingCard);
