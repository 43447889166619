import React, { forwardRef } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import DatePicker from 'react-datepicker';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QInput } from './QInput';
import { QIconButton } from './QIconButton';
import { QInputIconWrapper } from './QInputIconWrapper';

const CustomDatePicker = forwardRef(
  ({ value, onClick, onChange, placeholder, ...props }, ref) => (
    <QInput
      {...props}
      outlinedWithTitle
      size="small"
      label={placeholder}
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <>
            {value && (
              <QInputIconWrapper position="start">
                <QIconButton
                  onClick={() => onChange({ target: { value: '' } })}
                >
                  <ClearIcon />
                </QIconButton>
              </QInputIconWrapper>
            )}
          </>
        ),
      }}
    />
  ),
);

const LDDatePicker = ({ label, date, handleChangeDate, ...props }) => {
  const { mastV2 } = useFlags();

  return mastV2 ? (
    <DatePicker
      {...props}
      selected={date}
      onChange={handleChangeDate}
      placeholderText={label}
      customInput={<CustomDatePicker {...props} />}
    />
  ) : (
    <DatePicker
      {...props}
      selected={date}
      onChange={value => handleChangeDate(value)}
      placeholderText={label}
      isClearable={!!date}
    />
  );
};

export const QDatePicker = withLDConsumer()(LDDatePicker);
