/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';

import i18n from '../../localization/i18n';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QTypography } from '../Q-Components/QTypography';
import { QBadge } from '../Q-Components/QBadge';
import { QButton } from '../Q-Components/QButton';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QDivider } from '../Q-Components/QDivider';

import RiskLevel from './SimpleReport/ReportFindingCard/RiskLevel';
import { Regulations } from './SimpleReport/ReportFindingCard/Regulations';
import { EvidenceV2 } from './SimpleReport/ReportFindingCard/EvidenceV2/EvidenceV2';

export const IssueAccordion = ({
  issue,
  issueWithEvidence, // new report only fetches evidence for an issue when it's open
  ruleSummary = undefined,
  isExpanded = undefined,
  handleAccordionChange = undefined,
  isSarifReport = false,
}) => {
  const isNotEmpty = regulations => regulations && !_.isEmpty(regulations);

  const showDescription = Array.isArray(issue?.name)
    ? issue?.name[0] !== issue?.description
    : issue?.name !== issue?.description;

  return issue ? (
    <Accordion
      style={{
        backgroundColor: '#FAFAFA',
        boxShadow: 'none',
        borderRadius: '0px',
      }}
      data-testid="rule-finding"
      expanded={isExpanded}
      onChange={
        handleAccordionChange
          ? () => handleAccordionChange(!isExpanded)
          : undefined
      }
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon style={{ marginTop: '12px', marginBottom: '12px' }} />
        }
        style={{
          flexDirection: 'row-reverse',
          alignItems: 'start',
        }}
      >
        <QCol
          style={{
            marginLeft: '16px',
            marginTop: '3px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
          }}
        >
          <QRow>
            <QTypography variant="h5Regular">{issue.name}</QTypography>
          </QRow>

          <QRow columnGap="8px">
            {ruleSummary && (
              <QBadge
                variant="basic"
                style={{
                  backgroundColor: 'purple',
                  height: '24px',
                }}
                label={`${ruleSummary.occurrences} occurrences`}
              />
            )}
            <RiskLevel risk={issue.risk} />
            {issue.exploitable && (
              <QBadge
                variant="basic"
                style={{ backgroundColor: '#3B3E40', height: '24px' }}
                label="Exploitable"
              />
            )}
            {issue.cvss_score && (
              <QButton
                variant="outlined"
                endIcon={<LaunchIcon />}
                href={issue.cvss_link}
                style={{
                  fontSize: '12px',
                  height: '24px',
                }}
              >
                CVSS: {issue.cvss_score}
              </QButton>
            )}
            {issue.cwe && (
              <QButton
                variant="outlined"
                endIcon={<LaunchIcon />}
                href={issue.cwe_link}
                style={{
                  fontSize: '12px',
                  height: '24px',
                }}
              >
                {issue.cwe.split(':')[0]}
              </QButton>
            )}
          </QRow>
          {showDescription && (
            <QRow>
              <QTypography
                variant="h6Medium"
                style={{
                  color: '#757575',
                }}
              >
                {issue.description}
              </QTypography>
            </QRow>
          )}
        </QCol>
      </AccordionSummary>
      <AccordionDetails>
        {issueWithEvidence && (
          <QRow
            columnGap="16px"
            style={{
              width: '100%',
              paddingLeft: '40px',
              marginBottom: '16px',
            }}
          >
            {(issueWithEvidence.threat_profile ||
              issueWithEvidence.remediation ||
              issueWithEvidence.impact) && (
              <QCard
                style={{
                  padding: '8px',
                  backgroundColor: '#FFFFFF',
                  width: '100%',
                }}
              >
                <QCardBody
                  style={{
                    padding: '30px',
                  }}
                >
                  {issueWithEvidence.threat_profile && (
                    <QCol
                      style={{ display: 'flex', flexDirection: 'column' }}
                      rowGap="12px"
                    >
                      <QTypography
                        variant="h5Bold"
                        style={{
                          color: '#000000',
                        }}
                      >
                        Threat Details
                      </QTypography>
                      <p className="font-lg break-word">
                        {i18n.t(issueWithEvidence.threat_profile)}
                      </p>
                    </QCol>
                  )}
                  {issueWithEvidence.threat_profile &&
                    issueWithEvidence.remediation && (
                      <QDivider
                        style={{
                          borderColor: '#E0E0E0',
                          marginTop: '14px',
                          marginBottom: '14px',
                        }}
                      />
                    )}
                  {issueWithEvidence.remediation && (
                    <QCol
                      style={{ display: 'flex', flexDirection: 'column' }}
                      rowGap="12px"
                    >
                      <QTypography
                        variant="h5Bold"
                        style={{
                          color: '#000000',
                        }}
                      >
                        Remediation
                      </QTypography>
                      <p className="font-lg break-word">
                        {i18n.t(issueWithEvidence.remediation)}
                      </p>
                    </QCol>
                  )}
                  {issueWithEvidence.impact &&
                    (issueWithEvidence.remediation ||
                      issueWithEvidence.threat_profile) && (
                      <QDivider
                        style={{
                          borderColor: '#E0E0E0',
                          marginTop: '14px',
                          marginBottom: '14px',
                        }}
                      />
                    )}
                  {issueWithEvidence.impact && (
                    <QCol
                      style={{ display: 'flex', flexDirection: 'column' }}
                      rowGap="12px"
                    >
                      <QTypography
                        variant="h5Bold"
                        style={{
                          color: '#000000',
                        }}
                      >
                        Finding Impact
                      </QTypography>
                      <p className="font-lg break-word">
                        {i18n.t(issueWithEvidence.impact)}
                      </p>
                    </QCol>
                  )}
                </QCardBody>
              </QCard>
            )}
            {(isNotEmpty(issueWithEvidence.owasp) ||
              isNotEmpty(issueWithEvidence.niap) ||
              isNotEmpty(issueWithEvidence.gdpr)) && (
              <QCard
                style={{
                  padding: '8px',
                  backgroundColor: '#FFFFFF',
                  width: '100%',
                }}
              >
                <QCardHeader
                  disableTypography
                  title={
                    <QTypography
                      variant="h5Bold"
                      style={{
                        color: '#000000',
                      }}
                    >
                      Compliance
                    </QTypography>
                  }
                />
                <QCardBody>
                  <Regulations
                    issue={issueWithEvidence}
                    isSarifReport={isSarifReport}
                  />
                </QCardBody>
              </QCard>
            )}
          </QRow>
        )}

        {isNotEmpty(issueWithEvidence) && (
          <QCard
            style={{
              padding: '0',
              marginLeft: '40px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <QCardHeader disableTypography title="Evidence" />
            <QDivider />
            <QCardBody>
              <EvidenceV2 issue={issueWithEvidence} />
            </QCardBody>
          </QCard>
        )}
      </AccordionDetails>
    </Accordion>
  ) : null;
};
