import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import i18n from '../localization/i18n';
import util from '../logic/util';
import { setSidebarCollapsed } from '../store/slices/emmAppSlice';
import { getAssets } from '../assets/assets';

import { QSidebar } from './Q-Components/QSidebar';
import { QSidebarList } from './Q-Components/QSidebarList';
import { QSidebarItem } from './Q-Components/QSidebarItem';
import { QSidebarItemButton } from './Q-Components/QSidebarItemButton';
import { QSidebarItemIcon } from './Q-Components/QSidebarItemIcon';
import { QSidebarItemText } from './Q-Components/QSidebarItemText';
import { QSidebarSubHeader } from './Q-Components/QSidebarSubHeader';
import { QSidebarToolbar } from './Q-Components/QSidebarToolbar';
import { QSidebarFab } from './Q-Components/QSidebarFab';

const Sidebar = ({ ...props }) => {
  const appliance = process.env.REACT_APP_APPLIANCE ?? 'default';
  const [fabVisible, setFabVisible] = useState(false);
  const dispatch = useDispatch();
  const mastV2 = useFlags();

  const isCisaAppliance = appliance === 'cisa';

  const toggleCollapsed = () => {
    dispatch(setSidebarCollapsed(!props.sidebarCollapsed));
  };

  const adminPages = [
    ...(mastV2
      ? [
          {
            name: 'Users',
            route: '/kai/users',
            access: props.access.kai_access,
            iconClasses: 'fa-solid fa-users',
          },
          {
            name: 'Groups',
            route: '/kai/groups',
            access: props.access.kai_access,
            iconClasses: 'fa-solid fa-screen-users',
          },
          {
            name: 'Sub Groups',
            route: '/kai/sub-groups',
            access: props.access.kai_access,
            iconClasses: 'fa-solid fa-user-group',
          },
          {
            name: 'Report Settings',
            route: '/kai/report-settings',
            access: props.access.kai_access,
            iconClasses: 'fa-solid fa-gears',
          },
          {
            name: 'Banners',
            route: '/kai/banners',
            access: props.access.kai_access,
            iconClasses: 'fa-solid fa-flag',
          },
          {
            name: 'Licenses',
            route: '/kai/licenses',
            access: props.access.kai_access,
            iconClasses: 'fa-solid fa-file-certificate',
          },
          {
            name: 'Analyzed Applications',
            route: '/kai/analyzed-apps',
            access: props.access.kai_access,
            iconClasses: 'fa-solid fa-list',
          },
          {
            name: 'App Approvals',
            route: '/kai/app-approvals',
            access: props.access.kai_access,
            iconClasses: 'fa-solid fa-thumbs-up',
          },
        ]
      : [
          {
            name: 'KAI',
            route: '/kai',
            iconClasses: 'fa-solid fa-sitemap',
            access: props.access.kai_access,
          },
        ]),
    {
      name: 'Admin Analytics',
      route: '/admin/analytics',
      iconClasses: 'fa-regular fa-chart-mixed',
      access: props.access.kai_access,
    },
    {
      name: 'MAV Monthly Statistics',
      route: '/admin/mav-monthly-statistics',
      access: props.access.kai_access && isCisaAppliance,
      iconClasses: 'fa-regular fa-chart-simple',
    },
  ];

  const analysisPages = [
    {
      name: 'MDM Integration',
      route: '/mdm-integration',
      iconClasses: 'fa-solid fa-mobile-screen',
      access: props.access.mdm_integration,
    },
    {
      name: i18n.t('AnalyzedApplication'),
      route: '/dashboard',
      iconClasses: 'fa-regular fa-list',
      access: props.access.can_view_analyzed_apps,
    },
    {
      name: 'Firmware Analysis',
      route: '/analyzed-firmware',
      iconClasses: 'fa-regular fa-file-image',
      access: props.access.firmware_analysis,
    },
    {
      name: i18n.t('Submit App'),
      route: '/submit-app',
      iconClasses: 'fa-regular fa-cloud-arrow-up',
      access: props.access.can_submit,
      className: 'submit-app',
    },
    {
      name: i18n.t('App WatchList'),
      route: '/watchlist',
      iconClasses: 'fa-solid fa-binoculars',
      access: props.access.android_third_party || props.access.ios_third_party,
    },
    {
      name: i18n.t('CustomizeThreat'),
      route: '/customize-threat-score',
      iconClasses: 'fa-solid fa-pencil',
      access: props.access.can_view_analyzed_apps,
    },
    {
      name: i18n.t('Analytics'),
      route: '/analytics',
      iconClasses: 'fa-regular fa-chart-mixed',
      access: true,
    },
    {
      name: i18n.t('App Analysis Approvals'),
      route: '/app-analysis-approvals',
      iconClasses: 'fa-regular fa-check',
      access: props.appApprovalEnabled,
    },
  ];

  // sidebar-nav root
  return (
    <div>
      {props.flags.mastV2 ? (
        <QSidebar
          PaperProps={{
            onMouseOver: () => setFabVisible(true),
            onFocus: () => setFabVisible(true),
            onMouseLeave: () => setFabVisible(false),
          }}
          fab={
            <QSidebarFab
              onMouseOver={() => setFabVisible(true)}
              onFocus={() => setFabVisible(true)}
              onMouseLeave={() => setFabVisible(false)}
              variant={props.sidebarCollapsed ? 'collapsed' : 'expanded'}
              onClick={toggleCollapsed}
              disableRipple
              sx={{
                zIndex: 1201,
                position: 'fixed',
                color: '#487F87',
                display: fabVisible ? 'inline-block' : 'none',
                '&.MuiFab-root:focus': {
                  outline: '2px auto #FFA38B',
                },
              }}
            >
              {props.sidebarCollapsed ? (
                <i className="fa-solid fa-chevron-right" />
              ) : (
                <i className="fa-solid fa-chevron-left" />
              )}
            </QSidebarFab>
          }
          toolbar={
            <QSidebarToolbar
              sx={{
                position: 'sticky !important',
                top: 0,
              }}
            >
              {props.sidebarCollapsed ? (
                <img
                  src={getAssets[appliance].logo.side}
                  alt="quokka-logo"
                  width={24}
                  height={24}
                />
              ) : (
                <img
                  src={getAssets[appliance].logo.main}
                  alt="quokka-logo"
                  style={
                    isCisaAppliance
                      ? {
                          height: 56,
                          transform: 'scale(0.8)', // Scale the image by 60%
                          transformOrigin: 'center', // Keep the scaling centered
                        }
                      : {
                          width: 128,
                          height: 56,
                        }
                  }
                />
              )}
            </QSidebarToolbar>
          }
          variant="permanent"
          sx={{
            // Z-index for the sidebar (drawer) by default in MUI is 1200
            // https://mui.com/material-ui/customization/z-index/
            zIndex: 1200,
            overflow: 'hidden',
            [`& .MuiDrawer-paper`]: {
              overflow: 'hidden',
              width: props.sidebarCollapsed ? '52px' : '224px',
            },
          }}
        >
          <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            {props.access.kai_access && (
              <QSidebarList>
                {props.sidebarCollapsed ? (
                  <div />
                ) : (
                  <QSidebarSubHeader>Admin</QSidebarSubHeader>
                )}
                {adminPages.map(
                  page =>
                    page.access && (
                      <QSidebarItem>
                        <QSidebarItemButton component={Link} to={page.route}>
                          <QSidebarItemIcon>
                            <i className={page.iconClasses} />
                          </QSidebarItemIcon>
                          {props.sidebarCollapsed ? (
                            <div />
                          ) : (
                            <QSidebarItemText>{page.name}</QSidebarItemText>
                          )}
                        </QSidebarItemButton>
                      </QSidebarItem>
                    ),
                )}
              </QSidebarList>
            )}

            <QSidebarList>
              {props.sidebarCollapsed ? (
                <div />
              ) : (
                <QSidebarSubHeader>{i18n.t('AnalysisMenu')}</QSidebarSubHeader>
              )}
              {analysisPages.map(
                page =>
                  page.access && (
                    <QSidebarItem className={page.className}>
                      <QSidebarItemButton component={Link} to={page.route}>
                        <QSidebarItemIcon>
                          <i className={page.iconClasses} />
                        </QSidebarItemIcon>
                        {props.sidebarCollapsed ? (
                          <div />
                        ) : (
                          <QSidebarItemText>{page.name}</QSidebarItemText>
                        )}
                      </QSidebarItemButton>
                    </QSidebarItem>
                  ),
              )}
            </QSidebarList>

            {/* Portal version */}
            {props.sidebarCollapsed ? (
              <div />
            ) : (
              <div style={{ position: 'relative', left: 12 }}>
                <i style={{ marginRight: 12, color: '#94a0b2' }}>
                  <a
                    href="https://kryptowire.zendesk.com/hc/en-us/articles/360056748914-Release-Notes"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    target="__blank"
                  >
                    v{process.env.REACT_APP_VERSION}{' '}
                  </a>
                </i>
                {appliance && appliance !== 'default' && (
                  <p>
                    <i style={{ marginRight: 12, color: '#94a0b2' }}>
                      Powered by Quokka
                    </i>
                  </p>
                )}
              </div>
            )}
          </div>
        </QSidebar>
      ) : (
        <div className="sidebar">
          <nav className="sidebar-nav kryptowire-sidenav">
            <Nav>
              {props.access.kai_access && (
                <>
                  <li className="nav-title">{i18n.t('Admin')}</li>
                  <NavItem className="kai">
                    <NavLink
                      to="/kai"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className="fa-solid fa-sitemap" />
                      KAI
                    </NavLink>
                  </NavItem>
                  <NavItem className="app-analytics">
                    <NavLink
                      to="/admin/analytics"
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className="fa-regular fa-chart-mixed" />{' '}
                      {i18n.t('Admin Analytics')}
                    </NavLink>
                  </NavItem>
                  {isCisaAppliance && (
                    <NavItem className="mav-monthly-statistics">
                      <NavLink
                        to="/admin/mav-monthly-statistics"
                        className="nav-link"
                        activeClassName="active"
                      >
                        <i className="fa-regular fa-chart-simple" />{' '}
                        {i18n.t('MAV Monthly Statistics')}
                      </NavLink>
                    </NavItem>
                  )}
                </>
              )}

              <li className="nav-title">{i18n.t('AnalysisMenu')}</li>

              {props.access.mdm_integration && (
                <NavItem>
                  <NavLink
                    to="/mdm-integration"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="fa-solid fa-mobile-screen" />
                    MDM Integration
                  </NavLink>
                </NavItem>
              )}

              {props.access.can_view_analyzed_apps && (
                <NavItem>
                  <NavLink
                    to="/dashboard"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="fa-regular fa-list" />
                    {i18n.t('AnalyzedApplication')}
                  </NavLink>
                </NavItem>
              )}

              {props.access.firmware_analysis && (
                <NavItem>
                  <NavLink
                    to="/analyzed-firmware"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="fa-regular fa-file-image" />
                    Firmware Analysis
                  </NavLink>
                </NavItem>
              )}

              <div className="submit-app">
                {(props.access.android_analysis || props.access.ios_analysis) &&
                  props.access.can_submit && (
                    <NavItem>
                      <NavLink
                        to="/submit-app"
                        className="nav-link "
                        activeClassName="active"
                      >
                        <i className="fa-regular fa-cloud-arrow-up" />
                        {i18n.t('Submit App')}
                      </NavLink>
                    </NavItem>
                  )}
              </div>

              {(props.access.android_third_party ||
                props.access.ios_third_party) && (
                <NavItem className="app-watchlist">
                  <NavLink
                    to="/watchlist"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="fa-solid fa-binoculars" />
                    {i18n.t('App WatchList')}
                  </NavLink>
                </NavItem>
              )}

              {props.access.can_view_analyzed_apps && (
                <NavItem className="customize-threat">
                  <NavLink
                    to="/customize-threat-score"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="fa-solid fa-pencil" />
                    {i18n.t('CustomizeThreat')}
                  </NavLink>
                </NavItem>
              )}

              <NavItem className="app-analytics">
                <NavLink
                  to="/analytics"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="fa-regular fa-chart-mixed" />{' '}
                  {i18n.t('Analytics')}
                </NavLink>
              </NavItem>

              {props.appApprovalEnabled && (
                <NavItem className="">
                  <NavLink
                    to="/app-analysis-approvals"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="fa-regular fa-check" />{' '}
                    {i18n.t('App Analysis Approvals')}
                  </NavLink>
                </NavItem>
              )}
              <li className="divider" />

              <li className="nav-title">{i18n.t('SupportMenu')}</li>

              <NavItem className="documentation">
                <NavLink
                  to="/documentation"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="fa-solid fa-file" />
                  {i18n.t('Documentation')}
                </NavLink>
              </NavItem>

              <NavItem className="support-ticket">
                <a
                  href={util.zendeskUrls[process.env.REACT_APP_APPLIANCE]}
                  target="_blank"
                  className="nav-link"
                >
                  <i className="fa-solid fa-ticket" />
                  {i18n.t('Support Ticket')}
                </a>
              </NavItem>
            </Nav>

            {/* Portal version */}
            <div style={{ position: 'relative', top: 50, left: 12 }}>
              <i style={{ marginRight: 12, color: '#94a0b2' }}>
                <a
                  href="https://kryptowire.zendesk.com/hc/en-us/articles/360056748914-Release-Notes"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  target="__blank"
                >
                  v{process.env.REACT_APP_VERSION}{' '}
                </a>
              </i>
              {appliance && appliance !== 'default' && (
                <p>
                  <i style={{ marginRight: 12, color: '#94a0b2' }}>
                    Powered by Quokka
                  </i>
                </p>
              )}
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ emmApp }) => ({
  access: emmApp.userAccess,
  lang: emmApp.portalConfig.lang,
  sidebarCollapsed: emmApp.sidebarCollapsed,
});

export default connect(mapStateToProps)(withLDConsumer()(Sidebar));
