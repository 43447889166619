import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector, useDispatch } from 'react-redux';

import { fetchKaiGroups } from '../../../store/sliceHelpers/kaiSliceHelper';
import { toastrHelper } from '../../../logic/toastrHelper';
import { QTypography } from '../../Q-Components/QTypography';
import { QRow } from '../../Q-Components/QRow';
import { QCol } from '../../Q-Components/QCol';
import { QCard } from '../../Q-Components/QCard';
import { QButton } from '../../Q-Components/QButton';
import { QInput } from '../../Q-Components/QInput';
import { QLabel } from '../../Q-Components/QLabel';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QSelect } from '../../Q-Components/QSelect';
import { QDatePicker } from '../../Q-Components/QDatePicker';

const BannerSelector = ({ banners, selectedBanner, setSelectedBanner }) => {
  const { mastV2 } = useFlags();

  const handleBannerChange = (event, banner) => {
    const value = mastV2 ? banner.props.value : event.value;

    const newBanner = banners.find(
      bannerOption => value === bannerOption.value,
    );

    setSelectedBanner(newBanner);
  };

  return (
    <QSelect
      size="small"
      options={banners}
      value={mastV2 ? selectedBanner.value : selectedBanner}
      onChange={handleBannerChange}
      label="Banner"
      selectIconProps={{ fontSize: 'medium' }}
      formControlProps={{ sx: { width: '100%' } }}
    />
  );
};

const BannerGroupConfiguration = ({
  selectedBanner,
  groups,
  selectedGroups,
  setSelectedGroups,
}) => {
  const { mastV2 } = useFlags();

  const groupOptions = groups.map(group => ({
    label: group.name,
    value: group.id,
  }));

  useEffect(() => {
    if (selectedBanner?.userGroups) {
      setSelectedGroups(selectedBanner.userGroups.map(group => group.id));
    }
  }, [selectedBanner]);

  const handleGroupSelection = (event, group) => {
    const value = mastV2
      ? group.props.value
      : group?.option?.value || group?.removedValue?.value;

    if (selectedGroups.includes(value)) {
      setSelectedGroups(selectedGroups.filter(id => id !== value));
    } else {
      setSelectedGroups([...selectedGroups, value]);
    }
  };

  return (
    <QSelect
      multiple
      isMulti
      options={groupOptions}
      value={
        mastV2
          ? selectedGroups
          : groupOptions.filter(group => selectedGroups.includes(group.value))
      }
      onChange={handleGroupSelection}
      label="Groups"
      formControlProps={{ sx: { width: '100%' } }}
      selectIconProps={{ fontSize: 'medium' }}
    />
  );
};

const BannerConfiguration = ({ selectedBanner, groups }) => {
  const { mastV2 } = useFlags();

  const severityOptions = [
    { value: 'INFO', label: 'Info' },
    { value: 'WARNING', label: 'Warning' },
    { value: 'ERROR', label: 'Error' },
    { value: 'SUCCESS', label: 'Success' },
  ];

  const [isChanged, setIsChanged] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [newTitle, setNewTitle] = useState(selectedBanner.title);
  const [newNotification, setNewNotification] = useState(
    selectedBanner.notification,
  );
  const [newExpiresOn, setNewExpiresOn] = useState(
    moment(selectedBanner.expiresOn).toDate(),
  );
  const [newLaunchesOn, setNewLaunchesOn] = useState(
    moment(selectedBanner.launchesOn).toDate(),
  );
  const [newSeverity, setNewSeverity] = useState(selectedBanner.severity);

  useEffect(() => {
    setNewTitle(selectedBanner.title);
    setNewNotification(selectedBanner.notification);
    setNewExpiresOn(moment(selectedBanner.expiresOn).toDate());
    setNewLaunchesOn(moment(selectedBanner.launchesOn).toDate());
    setNewSeverity(selectedBanner.severity);
  }, [selectedBanner]);

  const handleSeverityChange = (event, severity) => {
    setNewSeverity(mastV2 ? severity.props.value : event.value);
  };

  useEffect(() => {
    const checkIfChanged = () =>
      newTitle !== selectedBanner.title ||
      newNotification !== selectedBanner.notification ||
      newExpiresOn?.getTime() !==
        moment(selectedBanner.expiresOn).toDate().getTime() ||
      newLaunchesOn?.getTime() !==
        moment(selectedBanner.launchesOn).toDate().getTime() ||
      newSeverity !== selectedBanner.severity ||
      !_.isEqual(
        selectedGroups,
        selectedBanner.userGroups?.map(group => group.id),
      );

    setIsChanged(checkIfChanged());
  }, [
    newTitle,
    newNotification,
    newExpiresOn,
    newLaunchesOn,
    newSeverity,
    selectedGroups,
    selectedBanner,
  ]);

  const handleSave = async () => {
    try {
      await axios.put(`user-groups-notifications/update`, {
        id: selectedBanner.id,
        title: newTitle,
        notification: newNotification,
        expiresOn: newExpiresOn,
        launchesOn: newLaunchesOn,
        severity: newSeverity,
        userGroupIds: selectedGroups,
      });

      window.location.reload();
    } catch (error) {
      toastrHelper.showErrorToast('Error updating banner', 'Error', mastV2);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `user-groups-notifications/delete/${selectedBanner.id}`,
      );

      window.location.reload();
    } catch (error) {
      toastrHelper.showErrorToast('Error deleting banner', 'Error', mastV2);
    }
  };

  return (
    <QCol xs="12" flex="1">
      <QRow sx={{ mt: '12px' }}>
        <QLabel htmlFor="new-banner-title">
          <QTypography variant="h5Medium">Title</QTypography>
        </QLabel>
      </QRow>
      <QRow>
        <QInput
          id="banner-title-input"
          data-testid="banner-title-input"
          value={newTitle}
          onChange={e => setNewTitle(e.target.value)}
          placeholder="Title"
          fullWidth
        />
      </QRow>
      <QRow sx={{ mt: '12px' }}>
        <QLabel htmlFor="new-banner-message">
          <QTypography variant="h5Medium">Message</QTypography>
        </QLabel>
      </QRow>
      <QRow>
        <QInput
          value={newNotification}
          onChange={e => setNewNotification(e.target.value)}
          placeholder="Notification"
          fullWidth
        />
      </QRow>

      <QRow sx={{ mt: '12px', columnGap: '16px' }}>
        <QDatePicker
          date={newLaunchesOn}
          handleChangeDate={setNewLaunchesOn}
          label="Launch On"
          sx={{ width: '100%' }}
        />
        <QDatePicker
          date={newExpiresOn}
          handleChangeDate={setNewExpiresOn}
          label="Expire On"
          sx={{ width: '100%' }}
        />
      </QRow>

      <QRow sx={{ mt: '12px' }}>
        <QSelect
          options={severityOptions}
          value={
            mastV2 ? newSeverity : { value: newSeverity, label: newSeverity }
          }
          onChange={handleSeverityChange}
          label="Type"
          selectIconProps={{ fontSize: 'medium' }}
          formControlProps={{ sx: { width: '100%' } }}
        />
      </QRow>
      <QRow sx={{ mt: '12px' }}>
        <BannerGroupConfiguration
          selectedBanner={selectedBanner}
          groups={groups}
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
        />
      </QRow>

      <QRow sx={{ mt: '12px', columnGap: '16px' }}>
        <QButton
          className="mt-2"
          block
          onClick={handleSave}
          color="primary"
          disabled={!isChanged || !newTitle}
          variant="filled"
        >
          Save
        </QButton>
        <QButton
          className="mt-2"
          block
          onClick={handleDelete}
          color="primary"
          disabled={selectedBanner.id === -1}
          variant="filled"
        >
          Delete
        </QButton>
      </QRow>
    </QCol>
  );
};

export const Banners = () => {
  const [banners, setBanners] = useState([
    {
      id: -1,
      value: -1,
      label: 'Create New Banner',
      title: '',
      notification: '',
      expiresOn: moment().add(14, 'days').toDate(),
      launchesOn: moment().toDate(),
      severity: 'INFO',
      userGroups: [],
    },
  ]);
  const [selectedBanner, setSelectedBanner] = useState(banners[0]);

  const groups = useSelector(state => state.kai.kaiGroups);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchKaiGroups());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await axios.get(`user-groups-notifications/all`).then(response => {
        const bannerOptions = response.data.map(banner => ({
          ...banner,
          value: banner.id,
          label: banner.title,
          expiresOn: banner.expiresOn || moment().add(14, 'days').toDate(),
          launchesOn: banner.launchesOn || moment().toDate(),
        }));
        setBanners([...banners, ...bannerOptions]);
      });
    })();
  }, []);

  return (
    <QRow sx={{ paddingTop: '20px' }}>
      <QCol xs="12" flex="1">
        <QCard style={{ width: '100%' }} className="card-accent-primary">
          <QCardHeader
            className="flex justify-between align-center"
            title={
              <QTypography variant="h4Bold" color="#000000">
                Banner Configuration
              </QTypography>
            }
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>
                <strong>Banner Configuration</strong>
              </span>
            </div>
          </QCardHeader>

          <BannerSelector
            banners={banners}
            selectedBanner={selectedBanner}
            setSelectedBanner={setSelectedBanner}
          />

          {selectedBanner && groups && (
            <BannerConfiguration
              selectedBanner={selectedBanner}
              groups={groups}
            />
          )}
        </QCard>
      </QCol>
    </QRow>
  );
};
