import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Button,
  ModalFooter,
  FormGroup,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../../../localization/i18n';
import { toastrHelper } from '../../../logic/toastrHelper';
import {
  setParsedAppIssues,
  setThreatScore,
} from '../../../store/slices/appResultsSlice';

export const AppIssueStatusNote = ({
  open,
  toggle,
  appIssueName,
  appIssue,
}) => {
  if (!appIssue) {
    appIssue = {};
  }

  const reviewStatusOptions = [
    { value: 'default', label: i18n.t('Default') },
    { value: 'passed', label: i18n.t('Passed') },
  ];

  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [reviewStatus, setReviewStatus] = useState(
    reviewStatusOptions.find(option => option.value === 'default'),
  );
  const [saveReview, setSaveReview] = useState(false);

  const noteRequired = useSelector(
    state => state.emmApp.userConfig.note_required_review_status,
  );

  const dispatch = useDispatch();
  const { farmAppId } = useParams();
  const { mastV2 } = useFlags();

  useEffect(() => {
    const currentStatus = appIssue.review_status || 'default';
    setNote(appIssue.note || '');
    setReviewStatus(
      reviewStatusOptions.find(option => option.value === currentStatus),
    );
  }, [appIssue?.id]);

  async function saveChanges() {
    console.log('Saving changes to app issue id:', appIssue.id);
    setLoading(true);

    try {
      const response = await axios.put(`app-issues/${appIssue.id}`, {
        reviewStatus: reviewStatus.value,
        note,
        appliedAllVersion: saveReview,
      });

      if (response.status !== 200) {
        toastrHelper.showErrorToast(response.data.msg, i18n.t('Error'), mastV2);
      }

      toastrHelper.showSuccessToast(
        response.data.msg,
        i18n.t('Success'),
        mastV2,
      );
    } catch (error) {
      toastrHelper.showErrorToast(
        error.response.data.msg,
        i18n.t('Error'),
        mastV2,
      );
    }

    setSaveReview(false);

    const threatScore = await axios.get(`api/threat-score?uuid=${farmAppId}`);

    // The evidence should be returned here, if an app status was changed from passed to default, we need the evidence to render the report finding card
    const newParsedIssues = await axios.get(
      `app-issues/parsed?uuid=${farmAppId}&evidence=true`,
    );

    dispatch(setParsedAppIssues(newParsedIssues.data.parsedAppIssues));
    dispatch(setThreatScore(threatScore.data.threatScore));

    setLoading(false);
    toggle();
  }

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader>
        {i18n.t('Edit App Issue')}: {i18n.t(appIssueName)}
      </ModalHeader>

      <ModalBody>
        <Label for="app-issue-note-input">
          <strong>{i18n.t('Note')}:</strong>
        </Label>
        <Input
          type="textarea"
          id="app-issue-note-input"
          value={note}
          onChange={e => setNote(e.target.value)}
        />

        <Button color="warning" className="mt-2" onClick={() => setNote('')}>
          {i18n.t('Clear Note')}
        </Button>
        <span style={{ float: 'right' }}>
          <small>
            {noteRequired ? (
              <i>{i18n.t('Minimum 10 characters')}</i>
            ) : (
              <i>{i18n.t('Optional')}</i>
            )}
          </small>
        </span>

        {appIssue && appIssue.note_user_email && (
          <div className="mb-2">
            <small>
              <i>Last modified by: </i>
              {appIssue.note_user_email} on{' '}
              {moment(appIssue.note_updated).format('L - LTS')}
            </small>
          </div>
        )}
        <hr />
        <FormGroup>
          <Label for="review-status-input">
            <strong>{i18n.t('Review Status')}:</strong>
          </Label>
          <Select
            options={reviewStatusOptions}
            value={reviewStatus}
            onChange={option => setReviewStatus(option)}
          />
          {appIssue && appIssue.review_status_user_email && (
            <div className="mb-2">
              <small>
                <i>Last modified by: </i>
                {appIssue.review_status_user_email} on{' '}
                {moment(appIssue.review_status_updated).format('L - LTS')}
              </small>
            </div>
          )}
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <span style={{ position: 'absolute', left: 20 }}>
          <Label check>
            <Input
              type="checkbox"
              id="save-review"
              checked={saveReview}
              onChange={() => setSaveReview(!saveReview)}
            />
            &nbsp; Apply status and note to all versions?
          </Label>
        </span>
        <Button color="secondary" onClick={toggle} disabled={loading}>
          {i18n.t('Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={saveChanges}
          disabled={loading || (note.length < 10 && noteRequired)}
        >
          {i18n.t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
