import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import { useParams } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import util from '../../logic/util';
import { toastrHelper } from '../../logic/toastrHelper';
import ReportFindingCard from '../../components/Reports/SimpleReport/ReportFindingCard';
import QReportFindingCard from '../../components/Reports/SimpleReport/QReportFindingCard';
import { AppInfo } from '../../components/Reports/AppInfo';
import { MarketInfo } from '../../components/Reports/MarketInfo';
import { setResults } from '../../store/slices/appResultsSlice';
import { IorsChartCard } from '../../components/Sarif/IorsChartCard';
import { RiskScoresCard } from '../../components/RiskScoresCard';
import { SarifToolBar } from '../../components/SarifToolBar';
import { ComplianceChartCard } from '../../components/Sarif/ComplianceChartCard';
import { QCard } from '../../components/Q-Components/QCard';
import { QCardHeader } from '../../components/Q-Components/QCardHeader';
import { QDivider } from '../../components/Q-Components/QDivider';
import { QCardBody } from '../../components/Q-Components/QCardBody';
import { QTable } from '../../components/Q-Components/QTable';
import { QTableHead } from '../../components/Q-Components/QTableHead';
import { QTableRow } from '../../components/Q-Components/QTableRow';
import { QTableBody } from '../../components/Q-Components/QTableBody';
import { QTableCell } from '../../components/Q-Components/QTableCell';
import { QRow } from '../../components/Q-Components/QRow';
import { QCol } from '../../components/Q-Components/QCol';
import { QButton } from '../../components/Q-Components/QButton';
import { setPageTitle } from '../../store/slices/emmAppSlice';
import iosReportUtil from '../../logic/iosReportUtil';
import androidReportUtil from '../../logic/androidReportUtil';
import {
  getResults,
  getRules,
  aggregateResultsFromRuleId,
} from '../../logic/dopplerUtil';

export function SarifReport() {
  const [sarifData, setSarifData] = useState([]);
  const [jsonResults, setJsonResults] = useState({});
  const [app, setApp] = useState();
  const [riskScores, setRiskScores] = useState({});

  const [filteredResults, setFilteredResults] = useState([]);

  const { farmAppUuid, analyzedAppId } = useParams();
  const dispatch = useDispatch();

  const { sarifReport, mastV2 } = useFlags();

  const fetchSarifData = async () => {
    try {
      const { data } = await axios.get(
        `/api/results/sarif?uuid=${farmAppUuid}`,
      );

      const rules = getRules(data);
      const results = getResults(data);

      const aggregatedResults = aggregateResultsFromRuleId(rules, results);
      setSarifData(aggregatedResults);
      setFilteredResults(aggregatedResults);
    } catch (err) {
      toastrHelper.showErrorToast('Error loading SARIF data', 'Error', mastV2);
    }
  };

  const fetchJsonData = async () => {
    try {
      const { data } = await axios.get(`api/results/json?uuid=${farmAppUuid}`);
      dispatch(setResults(data));
      setJsonResults(data);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading Json results data',
        'Error',
        mastV2,
      );
    }
  };

  const getApp = async () => {
    try {
      if (analyzedAppId) {
        const response = await axios.get(`/emm/app/${analyzedAppId}`);
        setApp(response.data);
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading analyzed app data',
        'Error',
        mastV2,
      );
    }
  };

  const fetchRiskScores = async () => {
    try {
      const { data } = await axios.get(`api/threat-score?uuid=${farmAppUuid}`);
      setRiskScores(data);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading risk score data',
        'Error',
        mastV2,
      );
    }
  };

  const marketInfoExists =
    (app?.farm_app?.platform === 'ios' && !_.isEmpty(jsonResults?.market)) ||
    (app?.farm_app?.platform === 'android' &&
      (!_.isEmpty(jsonResults?.market) ||
        !_.isEmpty(jsonResults?.analysis?.store_info)));

  useEffect(() => {
    fetchSarifData();
    fetchJsonData();
    getApp();
    fetchRiskScores();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(jsonResults)) {
      dispatch(
        setPageTitle(
          app?.farm_app?.platform === 'ios'
            ? iosReportUtil.getAppName(jsonResults)
            : androidReportUtil.getAppName(jsonResults),
        ),
      );

      window.scrollTo(0, 0);
    }
  }, [jsonResults]);

  return (
    <>
      <div className="text-right mb-3">
        <QButton
          variant="filled"
          color="primary"
          data-testid="test-download-sarif-button"
          outline
          onClick={() => util.getResults({ uuid: farmAppUuid }, 'sarif')}
        >
          Export
        </QButton>
      </div>
      {sarifReport ? (
        <div>
          {app && (
            <QRow
              sx={{ columnGap: '20px', width: '100%' }}
              style={{ marginBottom: '20px' }}
            >
              <QCol
                xs={marketInfoExists ? '5' : '10'}
                sx={marketInfoExists ? { width: '45%' } : { width: '80%' }}
              >
                <AppInfo
                  platform={app.farm_app.platform}
                  results={jsonResults}
                  marketInfoExists={marketInfoExists}
                />
              </QCol>
              {marketInfoExists && (
                <QCol xs="5" sx={{ width: '35%' }}>
                  <MarketInfo
                    platform={app.farm_app.platform}
                    results={jsonResults}
                  />
                </QCol>
              )}

              {!_.isEmpty(riskScores) && (
                <QCol xs="2" sx={{ width: '20%' }}>
                  <RiskScoresCard riskScores={riskScores} />
                </QCol>
              )}
            </QRow>
          )}

          <QRow
            sx={{ columnGap: '20px', width: '100%' }}
            style={{ marginBottom: '20px' }}
          >
            <QCol xs="4" sx={{ width: '33%' }}>
              <IorsChartCard results={sarifData} />
            </QCol>
            <QCol xs="4" sx={{ width: '33%' }}>
              <ComplianceChartCard results={sarifData} compliance="owasp" />
            </QCol>
            <QCol xs="4" sx={{ width: '33%' }}>
              <ComplianceChartCard results={sarifData} compliance="niap" />
            </QCol>
          </QRow>

          {mastV2 ? (
            <>
              <QCard
                style={{
                  padding: '0',
                  marginBottom: '18px',
                  boxShadow: 'none',
                  borderRadius: '0',
                }}
              >
                <QCardHeader disableTypography title="Rules" />
                <QDivider />
                <QCardBody>
                  <SarifToolBar
                    aggregatedResults={sarifData}
                    setFilteredResults={setFilteredResults}
                  />
                </QCardBody>
              </QCard>
              <QTable data-testid="rules-table">
                <QTableHead>
                  <QTableRow hoverDisabled>
                    <QTableCell>Rules</QTableCell>
                  </QTableRow>
                </QTableHead>
                <QTableBody>
                  {filteredResults.map(result => (
                    <QReportFindingCard issue={result} />
                  ))}
                </QTableBody>
              </QTable>
            </>
          ) : (
            <>
              <SarifToolBar
                aggregatedResults={sarifData}
                setFilteredResults={setFilteredResults}
              />

              {filteredResults.map(result => (
                <ReportFindingCard issue={result} v2 />
              ))}
            </>
          )}
        </div>
      ) : (
        <div>No permissions to view this page</div>
      )}
    </>
  );
}
