import React, { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { Col, Row, Nav, NavItem, NavLink } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { EditGroupUser } from '../GroupAdmin/EditGroupUser';
import BulkEditPermissions from '../Settings/GroupAdmin/BulkEditPermissions';
import { setPageTitle } from '../../store/slices/emmAppSlice';

import AppApprovals from './AppApprovals/AppApprovals';
import { Users } from './Users/Users';
import { Licenses } from './Licenses/Licenses';
import { SubGroups } from './SubGroups/SubGroups';
import { Groups } from './Groups/Groups';
import { Banners } from './Banners/Banners';
import KaiAnalyzedApps from './AnalyzedApps/KaiAnalyzedApps';
import { KaiFarmApp } from './AnalyzedApps/components/KaiFarmApp';
import { ReportSettings } from './ReportSettings/ReportSettings';

export default function KAI() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { mastV2 } = useFlags();

  useEffect(() => {
    if (location.pathname === '/kai') {
      history.push('/kai/users');
    }
    dispatch(setPageTitle('KAI'));
  }, []);

  return (
    <div>
      <Row>
        {!mastV2 && (
          <Col xs="2">
            <Nav vertical className="secondary-sidebar bg-white">
              <NavItem>
                <NavLink href="#/kai/users" className="nav-link">
                  <span>Users</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#/kai/analyzed-apps" className="nav-link">
                  <span>Analyzed Apps</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#/kai/groups" className="nav-link">
                  <span>Groups</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#/kai/sub-groups" className="nav-link">
                  <span>Sub Groups</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#/kai/report-settings" className="nav-link">
                  <span>Report Settings</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#/kai/banners" className="nav-link">
                  <span>Banners</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#/kai/licenses" className="nav-link">
                  <span>Licenses</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#/kai/app-approvals" className="nav-link">
                  <span>App Approvals</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        )}

        <Col xs={mastV2 ? 12 : 10}>
          <Route path="/kai/sub-groups/" exact component={SubGroups} />
          <Route path="/kai/groups/" exact component={Groups} />
          <Route path="/kai/report-settings" exact component={ReportSettings} />
          <Route path="/kai/banners" exact component={Banners} />
          <Route path="/kai/licenses/" exact component={Licenses} />
          <Route path="/kai/users/" exact component={Users} />
          <Route path="/kai/analyzed-apps" exact component={KaiAnalyzedApps} />
          <Route path="/kai/farm-apps/:farmAppId" component={KaiFarmApp} />
          <Route
            path="/kai/users/:userId"
            render={() => <EditGroupUser kaiAccess />}
          />
          <Route
            path="/kai/bulk-editing"
            render={() => <BulkEditPermissions kaiAccess />}
          />
          <Route
            path="/kai/app-approvals"
            render={() => <AppApprovals kaiAccess />}
          />
        </Col>
      </Row>
    </div>
  );
}
