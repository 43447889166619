import React from 'react';

import { QDoughnut } from '../../Q-Components/QDoughnut';
import { QRow } from '../../Q-Components/QRow';
import { QCol } from '../../Q-Components/QCol';
import { QTypography } from '../../Q-Components/QTypography';

export const MaliciousScore = ({ score = 0 }) => {
  const maliciousScoreFloat = parseFloat(score);

  let color = '#ddd';
  if (maliciousScoreFloat >= 70) {
    color = '#F44336';
  } else if (maliciousScoreFloat >= 50) {
    color = '#FF9800';
  } else {
    color = '#2196F3';
  }

  return (
    <QRow style={{ position: 'relative', textAlign: 'center' }}>
      <QCol style={{ textAlign: 'center', position: 'relative' }}>
        <QDoughnut
          value={maliciousScoreFloat}
          data={{
            datasets: [
              {
                data:
                  maliciousScoreFloat === -1
                    ? [1, 0] // Neutral or no data scenario
                    : [maliciousScoreFloat, 100 - maliciousScoreFloat],
                backgroundColor:
                  maliciousScoreFloat === -1
                    ? ['#E0E0E0', '#E0E0E0'] // Gray color for "No Data" case
                    : [color, '#E0E0E0'],
                borderWidth: 0,
              },
            ],
          }}
          options={{
            circumference: 180,
            rotation: -90,
            animation: {
              animateRotate: false,
            },
            responsive: true,
            cutout: '80%',
            plugins: {
              tooltip: {
                enabled: false,
              },
              title: {
                display: true,
                text: 'Malicious Score',
                position: 'bottom',
                padding: { top: 10 },
                font: {
                  size: 14,
                  weight: 600,
                },
              },
            },
          }}
          noLegend
        />
        <div style={{ position: 'absolute', left: '11%', bottom: '0px' }}>
          <QTypography variant="body">0</QTypography>
        </div>
        <div style={{ position: 'absolute', right: '9%', bottom: '0px' }}>
          <QTypography variant="body">100</QTypography>
        </div>
      </QCol>
    </QRow>
  );
};
