import React from 'react';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QDropdown } from './Q-Components/QDropdown';
import { QDropdownInputLabel } from './Q-Components/QDropdownInputLabel';
import { QDropdownSelect } from './Q-Components/QDropdownSelect';
import { QDropdownSelectItem } from './Q-Components/QDropdownSelectItem';

export const FilterSelect = ({
  selectedValue,
  onChange,
  label,
  filteredKey,
  originalData,
  customOptions = [],
}) => {
  const { mastV2 } = useFlags();

  const generateOptions = () => {
    if (customOptions.length) {
      return customOptions;
    }

    return _.uniqBy(
      originalData.map(item => {
        const value = _.get(item, filteredKey);
        const label =
          typeof value === 'string' ? _.capitalize(value) : 'Not specified';

        return {
          label,
          value,
        };
      }),
      'label',
    );
  };

  const options = [{ label: 'All', value: 'all' }, ...generateOptions()];

  const handleSelectChange = event => {
    if (!mastV2) {
      return onChange(event);
    }

    const selected = event.target.value;
    // Find the selected option based on value
    const option = options.find(opt => opt.value === selected) || {
      label: 'All',
      value: 'all',
    };
    return onChange(option);
  };

  return (
    <div style={{ width: '200px' }}>
      {mastV2 ? (
        <QDropdown
          style={{ width: '100%', paddingTop: '10px', paddingLeft: '0px' }}
        >
          <QDropdownInputLabel
            disableAnimation
            shrink={false}
            style={{ transform: 'translate(8px, 16px) scale(1)' }}
          >
            {selectedValue.label || label}
          </QDropdownInputLabel>
          <QDropdownSelect
            variant="outlined"
            value={selectedValue}
            onChange={handleSelectChange}
            renderValue={() => {}}
            onOpen={() => {}}
            onClose={() => {}}
            style={{ paddingTop: '0px', paddingBottom: '4.5px' }}
            data-testid="select-filter"
          >
            {options.map(option => (
              <QDropdownSelectItem
                key={option.value}
                value={option.value}
                className={selectedValue.value === option.value ? 'hover' : ''}
              >
                {option.label}
              </QDropdownSelectItem>
            ))}
          </QDropdownSelect>
        </QDropdown>
      ) : (
        <QDropdownSelect
          data-testid="select-filter-v1"
          className="mr-2"
          id={`${filteredKey}-select`}
          placeholder={label}
          options={options}
          onChange={handleSelectChange}
        />
      )}
    </div>
  );
};
