/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { aggregateResultsForSingleRule } from '../../logic/dopplerUtil';
import { toastrHelper } from '../../logic/toastrHelper';

import { IssueAccordion } from './IssueAccordion';

export function IssueCard({ ruleSummary, uuid }) {
  const { mastV2 } = useFlags();

  const [isExpanded, setIsExpanded] = useState(false);
  const [evidence, setEvidence] = useState(null);

  const issue = aggregateResultsForSingleRule(
    ruleSummary.rule,
    {},
    ruleSummary.result_level_numeric,
  );

  const fetchEvidence = async () => {
    try {
      const { data } = await axios.get(
        `/analysis-results/evidence?uuid=${uuid}&ruleGuid=${ruleSummary.guid}`,
      );
      const evidence = data.map(evidence => evidence.result);

      const formattedEvidence = aggregateResultsForSingleRule(
        ruleSummary.rule,
        { [ruleSummary.rule.id]: evidence },
      );
      setEvidence(formattedEvidence);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error loading evidence data',
        'Error',
        mastV2,
      );
    }
  };

  const handleAccordionChange = nextState => {
    setIsExpanded(nextState);
    if (nextState && !evidence) {
      fetchEvidence();
    }
  };

  return (
    <IssueAccordion
      issue={issue}
      issueWithEvidence={evidence}
      ruleSummary={ruleSummary}
      isExpanded={isExpanded}
      handleAccordionChange={handleAccordionChange}
    />
  );
}
export default withRouter(IssueCard);
