import React, { useState, useRef } from 'react';
import NProgress from 'nprogress';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QTypography } from '../Q-Components/QTypography';
import { QCardBody } from '../Q-Components/QCardBody';
import { QInputGroup } from '../Q-Components/QInputGroup';
import { QInput } from '../Q-Components/QInput';
import { QInputGroupAddon } from '../Q-Components/QInputGroupAddon';
import { QButton } from '../Q-Components/QButton';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QModal } from '../Q-Components/QModal';
import { QModalContent } from '../Q-Components/QModalContent';
import HelpButton from '../HelpButton';
import AppSubmitConfirmModal from '../Modals/AppSubmitConfirmModal';
import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';
import { clearAppCredentials } from '../../store/slices/appSubmitSlice';
import { store } from '../../store/store';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';
import { invalidateAnalyticsCache } from '../../store/slices/cache/analyticsCacheSlice';

import { QSubmitButton } from './QSubmitButton';

const BinarySubmit = props => {
  const [submitButtonClass, setSubmitButtonClass] = useState('fa-upload');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [platform, setPlatform] = useState(props.platform || '');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [fileName, setFileName] = useState('');

  const { mastV2 } = useFlags();

  const fileInputRef = useRef(null);
  const dispatch = useDispatch(); // Get access to dispatch function

  const userAccess = useSelector(state => state.emmApp.userAccess);

  // Helper function to clear binary file input
  const clearFileInput = () => {
    fileInputRef.current.value = '';
    setFileName('');
  };

  const userHasAccessToPlatform = platform => {
    if (platform.toLowerCase() === 'android' && userAccess.android_analysis) {
      return true;
    }

    if (platform.toLowerCase() === 'ios' && userAccess.ios_analysis) {
      return true;
    }

    return false;
  };

  const filePlatform = fileName => {
    if (fileName.endsWith('.apk') || fileName.endsWith('.aab')) {
      return 'Android';
    }

    if (fileName.endsWith('.ipa')) {
      return 'iOS';
    }

    return 'Unknown';
  };

  const availableFileExtensions = () => {
    const extensions = [];

    if (userAccess.android_analysis) {
      extensions.push('.apk', '.aab');
    }

    if (userAccess.ios_analysis) {
      extensions.push('.ipa');
    }

    return extensions.join(',');
  };

  // Submit binary
  const submitBinary = async (
    _,
    externalId = null,
    subgroupIds = null,
    scripts,
  ) => {
    NProgress.start();
    setSubmitButtonClass('fa-spin fa-spinner');
    setSubmitButtonDisabled(true);
    setLoading(true);
    setShowConfirmModal(false);
    toastrHelper.showInfoToast(
      i18n.t('Submitting application...'),
      i18n.t('Info'),
      mastV2,
    );

    const appFile = document.getElementById(`file-input`).files[0];
    const appPlatform = platform.toLowerCase();

    // Create the form data to be uploaded
    const formData = new FormData();
    formData.append('app', appFile);
    formData.append('platform', appPlatform);
    formData.append('customUsername', store.getState().appSubmit.appUsername);
    formData.append('customPassword', store.getState().appSubmit.appPassword);
    formData.append(
      'customCredential',
      store.getState().appSubmit.appCustomCredential,
    );
    formData.append('externalId', externalId);
    formData.append('subgroupIds', subgroupIds);

    for (let i = 0; i < scripts.length; i++) {
      formData.append('scriptFiles', scripts[i]);
    }

    // Try to upload the app
    let response;
    try {
      response = await axios.post('api/submit', formData);

      const successMessage = 'Application submitted.';

      // We successfully submitted the app
      dispatch(invalidateAnalyzedAppsCache()); // Dispatch action using useDispatch
      dispatch(invalidateAnalyticsCache()); // Dispatch action using useDispatch
      toastrHelper.showSuccessToast(
        `${response.data.msg || successMessage}`,
        i18n.t('Success'),
        mastV2,
      );
    } catch (err) {
      console.log(err);
      toastrHelper.showErrorToast(
        `${i18n.t('Error submitting application')}: ${err.response.data.msg}`,
        i18n.t('Error'),
        mastV2,
      );
    }

    setSubmitButtonClass('fa-upload');
    setSubmitButtonDisabled(false);
    setLoading(false);

    NProgress.done();
    clearFileInput();
    setSubmitButtonDisabled(true);

    // Clear any custom credentials that might have been entered
    dispatch(clearAppCredentials()); // Dispatch action using useDispatch
  };

  /**
   * Process the event when the user selects a file to submit
   *
   * @param {any} event
   */
  const processFileChange = event => {
    const file = event.target.files[0];

    const platform = filePlatform(file.name);

    setPlatform(platform);
    setFileName(file.name);

    // Verify that the app chosen has a valid file extension
    if (platform === 'Unknown' || !platform) {
      toastrHelper.showErrorToast(
        i18n.t(
          'Make sure to select a valid file for one of our supported platforms.',
        ),
        i18n.t('File type error'),
        mastV2,
      );
      clearFileInput();
      return;
    }

    // Verify that the user has access to the provided platform
    if (!userHasAccessToPlatform(platform)) {
      toastrHelper.showErrorToast(
        i18n.t(
          'You do not have access to the platform associated with the provided file.',
        ),
        i18n.t('File type error'),
        mastV2,
      );
      clearFileInput();
      return;
    }

    setSubmitButtonDisabled(false);
  };

  return (
    <div>
      {showConfirmModal && (
        <AppSubmitConfirmModal
          isOpen={showConfirmModal}
          toggle={() => setShowConfirmModal(!showConfirmModal)}
          confirm={submitBinary}
          binarySubmit
        />
      )}
      <QModal
        open={loading}
        isOpen={loading}
        content={
          <QModalContent
            style={{
              textAlign: 'center',
            }}
          >
            <h3>{i18n.t('Uploading App')}</h3>
            <i className="fa-solid fa-spinner fa-spin fa-2x mt-3" />
          </QModalContent>
        }
      >
        <QModalContent className="text-center">
          <h3>{i18n.t('Uploading App')}</h3>
          <i className="fa-solid fa-spinner fa-spin fa-2x mt-3" />
        </QModalContent>
      </QModal>
      <QCard className="card-accent-purple">
        <QCardHeader
          title={
            <QTypography variant="h4Bold" color="#000000">
              {platform} {i18n.t('Binary Upload')}
            </QTypography>
          }
          action={<HelpButton helpItem="binaryUpload" />}
        >
          <strong>
            {platform} {i18n.t('Binary Upload')}
          </strong>
          &nbsp;
          <HelpButton helpItem="binaryUpload" />
        </QCardHeader>
        <QCardBody>
          {mastV2 ? (
            <QRow columnGap="8px">
              <QCol>
                <QButton
                  variant="filled"
                  onClick={() => {
                    document.getElementById('file-input').click();
                  }}
                  style={{
                    color: '#000000',
                    backgroundColor: '#E0E0E0',
                    fontSize: '18px',
                    height: '100%',
                  }}
                >
                  {i18n.t('Choose File')}
                </QButton>
              </QCol>
              <QCol flex="4">
                <QInput
                  inputProps={{
                    type: 'file',
                    accept: availableFileExtensions(),
                    name: 'app',
                    'data-testid': 'binary-input',
                  }}
                  onChange={e => processFileChange(e)}
                  inputRef={fileInputRef}
                  id="file-input"
                  style={{ display: 'none' }}
                />
                <QInput
                  placeholder="No file chosen"
                  value={fileName}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </QCol>
              <QCol>
                <QSubmitButton
                  variant="filled"
                  disabled={submitButtonDisabled}
                  onClick={() => setShowConfirmModal(true)}
                  startIcon={<i className={`fa-solid ${submitButtonClass}`} />}
                  content={i18n.t('Submit App')}
                  sx={{ height: '100%' }}
                />
              </QCol>
            </QRow>
          ) : (
            <QInputGroup>
              <QInput
                type="file"
                className="form-control"
                id="file-input"
                data-testid="binary-input"
                accept={availableFileExtensions()}
                name="app"
                onChange={e => processFileChange(e)}
                innerRef={fileInputRef}
              />
              <QInputGroupAddon addonType="append">
                <QSubmitButton
                  type="button"
                  color="primary"
                  className="btn-submit-app"
                  disabled={submitButtonDisabled}
                  onClick={() => setShowConfirmModal(true)}
                  startIcon={<i className={`fa-solid ${submitButtonClass}`} />}
                  content={i18n.t('Submit App')}
                />
              </QInputGroupAddon>
            </QInputGroup>
          )}
        </QCardBody>
      </QCard>
    </div>
  );
};

export default withRouter(BinarySubmit);
